import { Component, OnInit, AfterViewInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { UserService } from '../../../services/user.service';
import { GeneralService } from '../../../services/general/general.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit, AfterViewInit {
    user: any;
    private token = '';
    public userData;
    public isStaff;
    public unity;
    public unityList;
    public perms;
    public canChangeUnit =  false;
    constructor(public userblockService: UserblockService,
                private userService: UserService,
                private generalService: GeneralService
                ) {
        this.token = this.userService.getToken() || '' ;
        this.userData = this.userService.getUserData();
        this.isStaff = this.userData.is_staff;
        this.unity = this.userData.profile.unity;
        this.perms = this.userService.getUserPerms();
        const cp = this.perms.find(u => u.name === 'generals.can_change_unit');
        if (cp) {
            this.canChangeUnit = cp.value;
        } else {
            this.canChangeUnit = false;
        }
    }

    ngOnInit() {
        this.loadUser();
    }

    ngAfterViewInit() {
        this.loadUser();
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    changeUnit() {
        this.generalService.getUnities(this.token).subscribe(
            data => {
                this.unityList = data['results'];
                let list = {};
                for (let i = 0; i < this.unityList.length; i++) {
                    list[this.unityList[i].id] = this.unityList[i].name;
                }
                Swal.fire({
                    title: 'Cambiar de Unidad',
                    input: 'select',
                    inputOptions: list,
                    inputPlaceholder: 'Selecciona una unidad',
                    showCancelButton: true,
                    inputValidator: (value) => {
                      return new Promise((resolve) => {
                        if (value === '') {
                          console.log(value);
                          resolve('Seleccione una unidad');
                        } else {
                            this.changeUnity(value);
                            resolve('');
                        }
                      });
                    }
                  });
            },
            err => {
                console.log(err);
            }
        );
    }

    changeUnity(value) {
        const unityC = this.unityList.find(unity => unity.id === parseInt(value));
        this.generalService.changeUnity(this.token, unityC).subscribe(
            resp => {
                this.userData.profile.unity = resp['unity'];
                // this.userData.profile.unity.name = resp['unity']['name'];
                localStorage.setItem('userData', JSON.stringify(this.userData));
                localStorage.setItem('unity', this.userData.profile.unity.id);
                window.location.reload();
            },
            err => {
                console.log(err);
            }
        );
    }

    loadUser() {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        this.user = {
            name: userData.first_name,
            job: userData.profile.user_type.name || 'Sin Perfil',
        };
    }

}
