import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public global = global;
  private url = global.url;
  public identity;

  // http options used for making API calls
  private httpOptions: any;

  // the actual JWT token
  public token = '';

  // the token expiration date
  public token_expires = new Date;

  // the username of the logged in user
  public username = '';
  public unity;
  public userData = {};
      // username: '',
      // first_name: '',
      // last_name: '',
      // email: '',
      // profile: {
      //     user_type: {
      //         id_user_type: '',
      //         name: ''
      //     },
      //     doctor: null,
      //     unity: {
      //         name: '',
      //         lat: '',
      //         lon: ''
      //     }
      // }
  // };

  // error messages received from the login attempt
  public errors: any = [];

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  getUserInfo(id, token): Observable<any>  {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/userinfo/' + id + '/', { headers });
  }

  getUserPermissions(id, token): Observable<any>  {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/userpermissions/' + id + '/', { headers });
  }

  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  login(user): Observable<any> {
    return this.http.post(this.url + '/api-token-auth/', JSON.stringify(user), this.httpOptions);
  }

  // Refreshes the JWT token, to extend the time the user is logged in
  refreshToken() {
    this.http.post(this.url + '/api-token-refresh/', JSON.stringify({token: this.token}), this.httpOptions).subscribe(
      data => {
        this.updateData(data['token']);
      },
      err => {
        this.errors = err.error;
      }
    );
  }

  logout() {
    this.token = '';
    this.token_expires = new Date();
    this.username = '';
    localStorage.removeItem('identity');
    localStorage.removeItem('token');
    localStorage.removeItem('expira');
    localStorage.removeItem('userData');
    localStorage.removeItem('unity');
    localStorage.removeItem('perms');
  }

  updateData(token) {
    this.token = token;
    this.errors = [];
    // decode the token to read the username and expiration timestamp
    const token_parts = this.token.split(/\./);
    const token_decoded = JSON.parse(window.atob(token_parts[1]));
    this.token_expires = new Date(token_decoded.exp * 1000);
    this.username = token_decoded.username;
    console.log('antes de gusrad tkoken');
    this.getUserInfo(token_decoded.user_id, this.token).subscribe(
      async data => {
        this.getUserPermissions(token_decoded.user_id, this.token).subscribe(
          res => {
            this.userData = data;
            this.unity = this.userData['profile'].unity.id;
            this.saveToken(token, token_decoded, this.token_expires.getTime().toString(), this.userData, this.unity, res);
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdentity() {
    let identity = JSON.parse(localStorage.getItem('identity') || '{}');
    const token = localStorage.getItem('token');
    if (token) {
        if (token.length > 2) {
            const expira = Number(localStorage.getItem('expira'));
            const expiraDate = new Date();
            expiraDate.setTime(expira);
            if (expiraDate > new Date() ) {
                if (identity && identity !== 'undefined') {
                  this.username = identity.username;
                  return identity;
                } else {
                    localStorage.removeItem('identity');
                    localStorage.removeItem('token');
                    localStorage.removeItem('expira');
                    localStorage.removeItem('userData');
                    localStorage.removeItem('unity');
                }
            } else {
                identity = null;
            }
        }
    } else {
        identity = null;
    }
    return identity;
  }

  getUserData() {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData;
  }

  getUserPerms() {
    const userPerms = JSON.parse(localStorage.getItem('perms') || '{}');
    return userPerms;
  }


  getToken() {
      const token = localStorage.getItem('token');
      if (token && token !== 'undefined') {
          this.token = token;
      } else {
          this.token = '';
      }
      return token;
  }

  saveToken(token, identity, expires, userData, unity, permissions) {
      // let hoy = new Date();
      // hoy.setSeconds(3600 * 24);
      // localStorage.setItem('expira', hoy.getTime().toString());
      localStorage.setItem('expira', expires);
      localStorage.setItem('token', token);
      localStorage.setItem('identity', JSON.stringify(identity));
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('unity', unity);
      localStorage.setItem('perms', JSON.stringify(permissions));
  }

}
