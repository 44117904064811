import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor( private userService: UserService,
              private router: Router ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpReq = req.clone({
      // url: req.url.replace("http://", "https://")
    });
    return next.handle(httpReq).pipe(tap(() => {},
    (err) => {
      if (err.status == 401) {
          this.userService.logout();
          this.router.navigateByUrl('/login');
      }
    }));
  }

}
