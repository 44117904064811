import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { menu, menuDoctor } from './menu';
import { routes } from './routes';
import { UserService } from '../services/user.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
        PagesModule
    ],
    declarations: [
  ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    // constructor(public menuService: MenuService, tr: TranslatorService, public userService: UserService) {
    //     let ud = userService.getUserData();
    //     console.log(ud);
    //     let type = ud?.profile?.user_type?.id_user_type || '';
    //     if (type === 'doctor') {
    //         menuService.addMenu(menuDoctor);
    //     } else {
    //         menuService.addMenu(menu);
    //     }
    // }
}
