<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <!-- <div class="user-block-picture">
        <div class="user-block-status">
            <img class="img-thumbnail rounded-circle" [src]="user.picture" alt="Avatar" />
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div> -->
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }}  {{ user.name }}</span>
        <span class="user-block-role">{{ user.job }}</span>
        <span class="user-block-role" *ngIf="isStaff">Unidad {{ unity?.name }}</span>
        <button *ngIf="canChangeUnit" type="button" class="btn btn-secondary btn-xs" tooltip="Cambiar de Unidad" placement="top" (click)="changeUnit()">
            <i class="fas fa-exchange-alt"></i>
        </button>
    </div>
</div>