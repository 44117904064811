import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { global } from '../../../services/global.service';
import { GeneralService } from 'src/app/services/general/general.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {

  constructor(
    private generalService: GeneralService,
    private toastr: ToastrService
  ) { 
    this.token = undefined;
  }
  
  public participant = {
    id: 0,
    name: '',
    last_names: '',
    country: undefined,
    city: undefined,
    birth_date: undefined,
    email: '',
    gender: '',
  };

  ticket = {
    paper_ticket: '',
    ticket_key: '',
  }

  phisicalTicket;

  staticfolder = '';

  countries: Array<any> = [];
  cities: Array<any> = [];

  token: string|undefined;
  token2: string|undefined;
  token3: string|undefined;

  captchaResponse: string;
  captchaResponse2: string;
  captchaResponse3: string;

  working = false;
  ngOnInit(): void {
    if (environment.production) {
      this.staticfolder = global.staticfiles;
    }
    this.getCountries();
  }

  step = 0;

  changeStep(step) {
    this.step = step;
  }


  onCaptchaResolved(response: string): void {
    this.captchaResponse = response;
  }


  onCaptchaResolved2(response: string): void {
    this.captchaResponse2 = response;
  }

  onCaptchaResolved3(response: string): void {
    this.captchaResponse3 = response;
  }

  public onSubmit(form: NgForm): void {
    console.log(form);
    // if (form.invalid) {
    //   for (const control of Object.keys(form.controls)) {
    //     form.controls[control].markAsTouched();
    //   }
    //   return;
    // }

    if (form.valid && this.captchaResponse) {
      // Aquí envías los datos del formulario y la respuesta del CAPTCHA al servidor
      const formData = {
        ...form.value,
        captchaResponse: this.captchaResponse
      };
      this.working = true;
      this.generalService.createOpenEventParticipant(formData).subscribe(
        res => {
          this.working = false;

          this.toastr.success('Participante creado con éxito', 'Éxito');
          this.step = 3;
          this.participant.id = res['id'];
        },
        err => {
          this.working = false;
          this.toastr.error(JSON.stringify(err.error), 'Error');
        }
      )
    }
  }



  getCountries() {
    this.generalService.getOpenCountries().subscribe(
      dat => {
        this.countries = dat['results'];
      },
      err => {
        console.log(err);
      }
    );
  }

  cityChanged(event) {
    this.participant.city = this.cities.find(city => city.id === event);
  }

  countryChanged(event) {
    // this.participant.country = this.countries.find(country => country.id === event);
    this.generalService.getOpenCities(event).subscribe(
      dat => {
        this.cities = dat['results'];
      },
      err => {
        console.log(err);
      }
    );
  }
  
  ceros() {
    this.ticket.paper_ticket = String(this.ticket.paper_ticket).padStart(5, '0');
  }

  public onSubmitTicket(form: NgForm): void {
    console.log(form);
    
    if (form.valid && this.captchaResponse) {
      // Aquí envías los datos del formulario y la respuesta del CAPTCHA al servidor
      const formData = {
        ...form.value,
        participant: this.participant.id,
        captchaResponse: this.captchaResponse2
      };
      this.working = true;

      this.generalService.registerParticipantTicket(formData).subscribe(
        res => {
          this.working = false;

          this.toastr.success('Boleto registrado con éxito', 'Éxito');
          this.step = 4;
          this.phisicalTicket = res;
        },
        err => {
          this.working = false;

          this.toastr.error(JSON.stringify(err.error), 'Error');
        }
      )
    }
  }

  onSubmitTicketForgot(form: NgForm): void {
    console.log(form);
    
    if (form.valid && this.captchaResponse3) {
      // Aquí envías los datos del formulario y la respuesta del CAPTCHA al servidor
      const formData = {
        ...form.value,
        captchaResponse: this.captchaResponse3
      };
      this.working = true;

      this.generalService.ticketForgot(formData).subscribe(
        res => {
          this.working = false;
          this.participant = res['event_participant'];
          this.toastr.success('Boleto enviado con éxito', 'Éxito');
          this.step = 4;
          this.phisicalTicket = res;
        },
        err => {
          this.working = false;

          this.toastr.error(JSON.stringify(err.error), 'Error');
        }
      )
    }
  }

  descargarImagen(url: string) {
    // Crear un elemento <a> temporal
    const enlace = document.createElement('a');
    enlace.href = url;

    // Extraer el nombre del archivo de la URL y usarlo como nombre de descarga
    const nombreArchivo = url.split('/').pop();
    enlace.download = nombreArchivo ?? 'imagen_descargada.jpg';

    // Agregar el enlace al DOM y hacer clic en él
    document.body.appendChild(enlace);
    enlace.click();

    // Limpiar y remover el enlace del DOM
    document.body.removeChild(enlace);
  }


}
