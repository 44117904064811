import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import {UserService} from '../../../services/user.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MenuService } from '../../../core/menu/menu.service';
import { menu, menuDoctor } from '../../menu';
import { environment } from 'src/environments/environment';
import { global } from '../../../services/global.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;
    public staticfolder = '';

    public user: any;

    constructor(
        public settings: SettingsService, 
        fb: FormBuilder, 
        private userService: UserService, 
        private router: Router,
        private menuService: MenuService
        ) {

        this.valForm = fb.group({
            'username': [null, Validators.compose([Validators.required])],
            'password': [null, Validators.required]
        });

    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);
        }
    }

    ngOnInit() {
        this.user = {
            username: '',
            password: ''
        };
        if (environment.production) {
            this.staticfolder = global.staticfiles;
        }
    }


    login() {
        this.userService.login({'username': this.user.username, 'password': this.user.password}).subscribe(
            response => {
                //this.userService.updateData(response.token);
                const token = response.token;
                const token_parts = token.split(/\./);
                const token_decoded = JSON.parse(window.atob(token_parts[1]));
                const token_expires = new Date(token_decoded.exp * 1000);
                const username = token_decoded.username;
                this.userService.token = token;
                this.userService.getUserInfo(token_decoded.user_id, token).subscribe(
                  data => {
                    this.userService.getUserPermissions(token_decoded.user_id, token).subscribe(
                        res => {
                            const userData = data;
                            this.userService.saveToken(token, token_decoded, token_expires.getTime().toString(), userData, userData.profile.unity.id, res);
                            this.router.navigate(['/']);
                        //   this.userData = data;
                        //   this.unity = this.userData['profile'].unity.id;
                        //   this.saveToken(token, token_decoded, this.token_expires.getTime().toString(), this.userData, this.unity, res);
                        },
                        err => {
                          console.log(err);
                        }
                      );
                    
                  },
                  err => {
                    console.log(err);
                  }
                );
            },
            error => {
                let errtxt = '';
                if (error.status === 400) {
                    errtxt =  error.error.global[0];
                } else {
                    errtxt = 'Error desconocido';
                }
                Swal.fire({
                    title: 'Error al autenticar',
                    icon: 'error',
                    text: errtxt
                  });
            }
        );
    }

    // export class RoutesModule {
    //     constructor(public menuService: MenuService, tr: TranslatorService, public userService: UserService) {
    //         let ud = userService.getUserData();
    //         console.log(ud);
    //         let type = ud?.profile?.user_type?.id_user_type || '';
    //         if (type === 'doctor') {
    //             menuService.addMenu(menuDoctor);
    //         } else {
    //             menuService.addMenu(menu);
    //         }
    //     }
    // }

    refreshToken() {
        this.userService.refreshToken();
    }

    logout() {
        this.userService.logout();
    }

}
